var hostname = window.location.hostname;
var isProd = hostname === "sakura.snell.com.tw";
var disable = isProd ? false : true;
window.__bl = {
    config: {
        pid: "egrrccpv71@dbe9e0dcbe362e2",
        appType: "web",
        imgUrl: "https://arms-retcode.aliyuncs.com/r.png?",
        sendResource: true,
        enableLinkTrace: true,
        behavior: true,
        disabled: disable,
        environment: isProd ? "prod" : "local",
        page: window.pageName,
    },
};